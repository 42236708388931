import React from "react"
import {Layout, Navbar, Hero, Promo, Corporation, Plans, Download, Footer, Circle} from "../components";

const IndexPage = () => (
    <Layout>
        <Navbar/>
        <Hero/>
        <Promo/>
        <Corporation/>
        <Circle/>
        <Plans/>
        <Download/>
        <Footer/>
    </Layout>
)

export default IndexPage
